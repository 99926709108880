import { Controller } from '@hotwired/stimulus'

export class TabsController extends Controller {
  static targets = ['tab']

  toggle(event) {
    this.tabTargets.forEach((tab) => {
      if (tab.querySelector('a') === event.target) {
        tab.classList.add('active')
      } else {
        tab.classList.remove('active')
      }
    })
  }
}
