// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Simply use require('sanitize-html') and storing it in a var won't make
// the var available outside this file; we need to use a window function.
// https://github.com/apostrophecms/sanitize-html

import '@hotwired/turbo-rails'
import 'controllers'
import 'bootgrid_tables'
import 'helpers'
import 'specific'
import 'general'
import 'channels'

window.sanitizeHtml = require('sanitize-html')
